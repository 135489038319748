<template>
  <div class="register_new">
    <view-header title="Nowy klient" @back="$router.push('/')"></view-header>

    <div class="container">
      <div class="row">
        <form class="col" @submit="next($event)">
          <div v-if="$store.state.config.customerRegisterConfig.fields.email.enabled" class="row mb-4">
            <div class="col">
              <el-input
                  :class="{'is-invalid': formErrors.email}"
                  v-model="email" type="email" placeholder="Adres e-mail"
                  :required="$store.state.config.customerRegisterConfig.fields.email.required" autocomplete="off">
              </el-input>
              <form-control-errors :error-bag="formErrors" field="email"></form-control-errors>
            </div>
          </div>

          <div v-if="$store.state.config.customerRegisterConfig.fields.phone_number.enabled" class="row mb-4">
            <div class="col">
              <el-input :class="{'is-invalid': formErrors.phone_number}"
                        v-model="phone_number" type="text" name="phone_number" placeholder="Numer telefonu" inputmode="tel"
                        :required="$store.state.config.customerRegisterConfig.fields.phone_number.required" autocomplete="off">
              </el-input>
              <form-control-errors :error-bag="formErrors" field="phone_number"></form-control-errors>
            </div>
          </div>

          <div v-if="$store.state.config.customerRegisterConfig.fields.first_name.enabled" class="row mb-4">
            <div class="col">
              <el-input :class="{'is-invalid': formErrors.first_name}"
                        v-model="first_name" type="text" name="first_name" placeholder="Imię"
                        :required="$store.state.config.customerRegisterConfig.fields.first_name.required" autocomplete="off"></el-input>
              <form-control-errors :error-bag="formErrors" field="first_name"></form-control-errors>
            </div>
          </div>

          <div v-if="$store.state.config.customerRegisterConfig.fields.last_name.enabled" class="row mb-4">
            <div class="col">
              <el-input :class="{'is-invalid': formErrors.last_name}"
                        v-model="last_name" type="text" name="last_name" placeholder="Nazwisko"
                        :required="$store.state.config.customerRegisterConfig.fields.last_name.required" autocomplete="off"></el-input>
              <form-control-errors :error-bag="formErrors" field="last_name"></form-control-errors>
            </div>
          </div>

          <div v-if="$store.state.config.customerRegisterConfig.fields.postal_code.enabled" class="row mb-4">
            <div class="col">
              <el-input
                  :class="{'is-invalid': formErrors.postal_code}"
                  placeholder="Kod pocztowy"
                  v-maska="'##-###'"
                  size="large"
                  inputmode="numeric"
                  :required="$store.state.config.customerRegisterConfig.fields.postal_code.required"
                  autocomplete="off"
                  v-model="postal_code">
              </el-input>
              <form-control-errors :error-bag="formErrors" field="postal_code"></form-control-errors>
            </div>
          </div>

          <div v-if="$store.state.config.customerRegisterConfig.fields.gender.enabled" class="row mb-4">
            <div class="col">
              <el-select v-model="gender"
                         :class="{'is-invalid': formErrors.birthdate}"
                         size="large"
                         style="width: 100%"
                         :placeholder="'Płeć' + ($store.state.config.customerRegisterConfig.fields.required ? ' *' : '')">
                <el-option label="Mężczyzna" value="male"></el-option>
                <el-option label="Kobieta" value="female"></el-option>
                <el-option v-if="!$store.state.config.customerRegisterConfig.fields.required" label="Nie odpowiadam" value=""></el-option>
              </el-select>
              <form-control-errors :error-bag="formErrors" field="birthdate"></form-control-errors>
            </div>
          </div>

          <div v-if="$store.state.config.customerRegisterConfig.fields.birthday.enabled" class="row mb-4">
            <div class="col">
              <el-date-picker v-model="birthdate"
                              style="width: 100%"
                              format="YYYY-MM-DD"
                              value-format="YYYY-MM-DD"
                              prefix-icon="icon-calendar"
                              :class="{'is-invalid': formErrors.birthday}"
                              :placeholder="'Data urodzenia' + ($store.state.config.customerRegisterConfig.fields.birthday.required ? ' *' : '')">
              </el-date-picker>
              <form-control-errors :error-bag="formErrors" field="birthday"></form-control-errors>
            </div>
          </div>

          <div v-if="$store.state.config.customerRegisterConfig.fields.username.enabled" class="row mb-4">
            <div class="col">
              <el-input :class="{'is-invalid': formErrors.username}"
                        v-model="username" type="text" name="username" placeholder="Login"
                        :required="$store.state.config.customerRegisterConfig.fields.username.required" autocomplete="off"></el-input>
              <form-control-errors :error-bag="formErrors" field="username"></form-control-errors>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col text-center">
              <button class="btn btn-primary" type="submit">Dalej</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ViewHeader from "@/components/ViewHeader";
import api from '../../api'
import FormControlErrors from "@/components/FormControlErrors";

export default {
  name: 'UserData',
  components: {FormControlErrors, ViewHeader},
  data() {
    return {
      email: null,
      phone_number: null,
      first_name: null,
      last_name: null,
      postal_code: null,
      birthdate: null,
      username: null,
      gender: null,
      formErrors: {},
    }
  },
  methods: {
    next(e) {
      const that = this;
      e.preventDefault()
      that.formErrors = {}
      api.validateUserData(this.getData())
        .then(() => {
          that.$store.commit('SET_REGISTER_USER_DATA', this.getData())
          that.$router.push('/register-new-agreements')
        })
        .catch((error) => {
          console.log(error.response)
          if(error.response.status === 422) {
            that.formErrors = error.response.data.errors
          }
        })
    },
    getData() {
      let data = {
        email: this.email,
        phone_number: this.phone_number,
        first_name: this.first_name,
        last_name: this.last_name,
        postal_code: this.postal_code,
        username: this.username,
        birthday: this.birthdate,
        gender: this.gender,
      }
      for(var propName in data) {
        if(data[propName] === null || data[propName] === undefined) delete data[propName]
      }
      return data
    }
  }
}
</script>
