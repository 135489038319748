<template>
  <div class="customer_service">
    <div class="container">
      <div class="row my-5">
        <div class="col text-center">
          <brand></brand>
        </div>
      </div>

      <div v-if="confirmEnabled == 'true' && (!currentUser.personal_identity.email_verified_at && !currentUser.personal_identity.phone_number_verified_at)">
        <div class="alert alert-danger">Konto nie zostało zweryfikowane!</div>
      </div>
      <div class="row row-cols-3 justify-content-center">
        <div v-if="$store.state.config.features.register_event_participation"  class="col my-3">
          <tile-button label="Rejestracja udziału w wydarzeniu" icon="event" @click="eventParticipation"></tile-button>
        </div>
        <div v-if="$store.state.config.features.reward_issue" class="col my-3">
          <tile-button label="Wydanie nagrody" :active="confirmEnabled == 'false' || (confirmEnabled == 'true' && (currentUser.personal_identity.email_verified_at || currentUser.personal_identity.phone_number_verified_at))" icon="prize" @click="rewardSelect"></tile-button>
        </div>
        <div v-if="$store.state.config.features.receipt_register" class="col my-3">
          <tile-button label="Rejestracja paragonu" icon="add-receipt" :active="confirmEnabled == 'false' || ( confirmEnabled == 'true' && (currentUser.personal_identity.email_verified_at || currentUser.personal_identity.phone_number_verified_at))" @click="receiptList"></tile-button>
        </div>
        <div class="col my-3">
          <tile-button label="Przypisywanie nowej karty" icon="add-card" @click="cardManage"></tile-button>
        </div>
        <div class="col my-3">
          <tile-button label="Informacje o użytkowniku" icon="user" @click="userInfo"></tile-button>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col">
          <h6>Aktualnie obsługiwany użytkownik: <strong>{{ $store.getters.currentUserDisplayName }}</strong></h6>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col text-center">
          <button class="btn btn-primary" @click="back">Powrót do ekranu głównego</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TileButton from "@/components/TileButton";
import Brand from "@/components/Brand";
import {toRaw} from "vue";

export default {
  name: "CustomerService",
  components: {
    TileButton,
    Brand,
  },
  data() {
    return {
      confirmEnabled: process.env.VUE_APP_SHOULD_CONFIRMED,
      currentUser: null
    }
  },
  computed: {
    promotionalEvents() {
      return this.$store.state.config.promotionalEvents
    }
  },
  methods: {
    userInfo() {
      this.$router.push({ name: 'CurrentUserInfo', params: { refresh: true } })
    },
    receiptList() {
      if (this.confirmEnabled == 'false' || (this.confirmEnabled == 'true' && (this.currentUser.personal_identity.email_verified_at || this.currentUser.personal_identity.phone_number_verified_at))) {
        this.$router.push('/receipt-list')
      }
    },
    rewardSelect() {
      if (this.confirmEnabled == 'false' || (this.confirmEnabled == 'true' && (this.currentUser.personal_identity.email_verified_at || this.currentUser.personal_identity.phone_number_verified_at))) {
        let promotionalEvent = this.promotionalEvents[0]
        if (this.promotionalEvents.length > 1) {
          this.promotionalEvents.forEach(ev => {
            if (ev.is_public == 1) {
              promotionalEvent = ev;
            }
          })
        }
        this.$router.push('/event-reward/' + promotionalEvent.uuid)
      }
    },
    eventParticipation() {
      console.log(this.$store.state.currentUser)
      if (this.confirmEnabled == 'false' || (this.confirmEnabled == 'true' && (this.currentUser.personal_identity.email_verified_at || this.currentUser.personal_identity.phone_number_verified_at))) {
        if (this.promotionalEvents.length > 1) {
          // todo: don't exists
          // this.$router.push('/event-participate-select');

          this.$router.push('/event-participate/' + this.promotionalEvents[0].uuid);

        } else {
          this.$router.push('/event-participate/' + this.promotionalEvents[0].uuid);
        }
      }
    },
    cardManage() {
      this.$router.push('/card-manage')
    },
    back() {
      this.$store.dispatch('clearCurrentUser')
      this.$router.push('/')
    }
  },
  mounted() {
    console.log(this.confirmEnabled)
    console.log(process.env)
    console.log(this.$store.state.currentUser.personal_identity);
    this.currentUser = toRaw(this.$store.state.currentUser);
    console.log(this.currentUser);
  }
}
</script>

<style scoped>

</style>
